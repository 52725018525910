import { WorksIcon, expenses, inventory, sales } from "../../constants/import";

import React from "react";
import { motion } from "framer-motion";

const HowItWorks = () => {
  const data = [
    {
      title: "Inventory Management",
      desc: "Efficiently manage inventory with SPINGO. Track stock levels, get low-stock alerts, and streamline reordering. Sign Up Effortlessly- Quick guide on setting up a SPINGO account",
      img: inventory,
    },
    {
      title: "Expense Tracking",
      desc: "Keep your finances in check with SPINGO expense tracking feature. Easily record and categorize expenses, generate expense reports, and gain insights into your spending habits.",
      img: expenses,
    },
    {
      title: "Sales Monitoring",
      desc: " Gain real-time insights into your sales performance with SPINGO sales monitoring capabilities. Track revenue trends, analyze customer purchasing patterns, and optimize your sales strategy.",
      img: sales,
    },
  ];

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div className="w-full container">
      {/* Header */}
      <div className="w-full relative flex flex-col justify-center items-center">
        {/* Icon */}
        <img
          src={WorksIcon}
          alt="How it Works"
          className="absolute left-44 hidden lg:block"
        />
        {/* Text */}
        <div className="w-full lg:w-[40%]">
          <h1 className="text-black font-bold text-2xl text-center">
            How It Works
          </h1>
          <p className="text-center text-sm">
            Discover the SPINGO Difference – Smarter Operations, Better Results
          </p>
        </div>
      </div>
      {/* Content */}
      <div className="w-full flex flex-wrap justify-center pt-5">
        {data.map((item, index) => (
          <motion.div
            key={index}
            className="lg:w-[350px] w-[300px] rounded-lg bg-faint my-5 lg:mx-5"
            initial="hidden"
            whileInView="visible"
            variants={variants}
            viewport={{ once: true, amount: 0.2 }}
          >
            {/* Image */}
            <img
              src={item.img}
              className="w-full rounded-tl-lg rounded-tr-lg"
              alt={item.title}
            />
            {/* Desc */}
            <div className="p-4">
              <h1 className="text-md font-medium underline text-primary mb-2">
                {item.title}
              </h1>
              <p className="text-xs text-dark">{item.desc}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
