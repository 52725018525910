import React from "react";
interface ButtonProps {
  text: string;
  onPress: any;
  outlined?: boolean;
  width?: string;
  white?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  outlined,
  text,
  onPress,
  width,
  white,
}) => {
  return (
    <div
      onClick={() => onPress()}
      className={`${
        outlined
          ? "bg-white text-primary"
          : white
          ? "text-primary bg-white"
          : "bg-primary text-white"
      } ${
        width ? width : "w-full"
      } cursor-pointer my-2 rounded-full font-extralight text-center flex flex-row justify-center items-center px-5 py-5 text-xs border border-primary`}
    >
      {text}
    </div>
  );
};

export default Button;
