import React from "react";
import {
  AccessSpingo,
  Download,
  GetStarted,
  Hero,
  HowItWorks,
  Subscribe,
} from "../components/Home";
import { Footer, NavBar } from "../components/Layouts";
const Home = () => {
  return (
    <main className="">
      <NavBar />
      <Hero />
      <HowItWorks />
      <Download />
      <Subscribe />
      <GetStarted />
      <AccessSpingo />
      <Footer />
    </main>
  );
};

export default Home;
