import React from "react";
import { dOne, dTwo, dThree, dFour } from "../../constants/imports/about";

const DiscoverBluePrint = () => {
  return (
    <div className="container">
      {/* Header */}
      <div className="w-full flex lg:flex-row flex-col items-start justify-between">
        {/* Col 1 */}
        <div className="lg:w-[25%] w-full">
          <h1 className="lg:font-bold lg:text-2xl text-md font-semibold text-black">
            Discover our{" "}
            <span className="text-gray-600">Business blueprint</span>
          </h1>
          <p className="text-sm text-dark lg:mt-1">Our roadmap to success.</p>
        </div>
        {/* COl 2 */}
        <div className="lg:w-[73%] w-full">
          <p className="text-sm text-dark mt-2">
            Welcome to Spingo&#39;s business playbook, where we unveil the
            strategies and principles that drive our success. Explore our
            guiding philosophies, methodologies, and best practices that shape
            every aspect of our operations. Gain insights into how we innovate,
            collaborate, and deliver value to our customers.
          </p>
        </div>
      </div>
      {/* Gallery */}
      <div className="w-full mt-5">
        {/* Top */}
        <img src={dOne} alt="Gallery" className="w-full block" />
        <img src={dTwo} alt="Gallery" className="w-full lg:hidden block my-3" />
        <div className="w-full flex flex-row lg:gap-5 gap-3 my-5">
          <img src={dTwo} alt="Gallery" className="lg:w-[50%] w-full hide" />
          <img src={dThree} alt="Gallery" className="lg:w-[25%] w-[48%]" />
          <img src={dFour} alt="Gallery" className="lg:w-[25%] w-[48%]" />
        </div>
      </div>
    </div>
  );
};

export default DiscoverBluePrint;
