import React from "react";
import { Button } from "../Generic";

import { dLeft, dRight, downloadImg } from "../../constants/import";
import { FaDotCircle } from "react-icons/fa";

const Download = () => {
  const data = [
    "Invoicing Made Easy: A brief intro on creating and tracking invoices with SPINGO.",
    "Smart Inventory Management: Details on inventory tracking, including AI-powered expiry management.",
    "Accounting Simplified: Information on how SPINGO simplifies bookkeeping.",
    "Mobile Management: Highlighting the convenience of the mobile app for on-the-go business management.",
  ];

  return (
    <div className="bg-faint w-full lg:px-28 px-0 pb-10 lg:py-16 relative grid lg:flex flex-row justify-center items-center">
      {/* LeftIcon */}
      <img
        src={dLeft}
        alt="DLEFT"
        className="w-[200px] absolute left-0 bottom-32 hide"
      />

      {/* Image */}
      <div className="z-10 flex flex-row lg:flex-col justify-center items-center">
        <img
          src={downloadImg}
          alt="Download"
          className="lg:w-[400px] w-[100%] lg:mb-0 mb-5"
        />
      </div>
      {/* Desc */}
      <div className="w-full lg:w-[50%] lg:pl-10 px-8">
        <h1 className="text-xl font-semibold text-black lg:mb-0 mb-2">
          Experience the Future of Business Management
        </h1>
        <p className="text-[#969696] text-xs font-light lg:mb-6 mb-0">
          Elevate Your Business Efficiency with a Tailored Walkthrough
        </p>
        {/* Listings */}
        <div className="mb-3">
          {data.map((item) => (
            <div key={item} className="flex flex-row items-start py-2">
              <FaDotCircle size={15} className="text-primary" />
              <p className="ml-3 text-xs font-medium text-black">{item}</p>
            </div>
          ))}
        </div>
        {/* Button */}
        <div className="lg:w-[200px] w-full mt-5">
          <Button text="Download Mobile App" onPress={() => {}} />
        </div>
      </div>
      {/* Right Icon */}
      <img
        src={dRight}
        alt="DRIGHT"
        className="w-[80px] absolute right-0 top-12 hide"
      />
    </div>
  );
};

export default Download;
