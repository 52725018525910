import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { RiFacebookCircleLine } from "react-icons/ri";
import { SlSocialLinkedin } from "react-icons/sl";
import { FiYoutube } from "react-icons/fi";
import { RxTwitterLogo } from "react-icons/rx";

const contact = ["Help Center", "FAQs", "Tutorials", "Troubleshooting", "Contact Support"]
const company = [{ title: "Home", link: "" }, { title: "About Us", link: "about-us" }, { title: "Features", link: "features" }, { title: "Careers", link: "careers" }, { title: "Contact", link: "contact-us" },]
const resources = ["Blogs", "Privacy Policy", "Terms of Services", "Disclaimer"]
const subContact = ["Phone: +234 8145143613", "Email: info@spingo.app"]
const contactAddress = ["+234 814514361", "info@spingo.app"]

const menu_items = [{ title: "Home", link: "" }, { title: "About Us", link: "about-us" }, { title: "Features", link: "features" }, { title: "Contact", link: "contact-us" },]
const socialData = [{ Icon: RiFacebookCircleLine, link: "https://www.spingo.app/" },
{ Icon: RxTwitterLogo, link: "https://www.spingo.app/" },
{ Icon: FaInstagram, link: "https://www.instagram.com/spingohq" },
{ Icon: FaWhatsapp, link: "https://www.spingo.app/" },
{ Icon: FiYoutube, link: "https://www.spingo.app/" },
{ Icon: SlSocialLinkedin, link: "https://www.linkedin.com/company/spingo-app" },
];
export { contact, company, resources, subContact, socialData, contactAddress, menu_items }