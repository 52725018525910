import { Footer, NavBar } from "../components/Layouts";
import {
  FeatureContents,
  FeatureDownload,
  FeatureHeader,
} from "../components/Features";
import FeatureLine from "../components/Features/FeatureLine";
import React from "react";

const Features = () => {
  return (
    <>
      <NavBar />
      <FeatureHeader />
      <FeatureContents />
      <FeatureLine />
      <FeatureDownload />
      <Footer />
    </>
  );
};

export default Features;
