import { sLine, subscribeImg } from "../../constants/import";

import React from "react";
import { FiSend } from "react-icons/fi";
import { GrLinkNext } from "react-icons/gr";

const Subscribe = () => {
  return (
    <div className="bg-faint container relative grid lg:flex flex-row justify-start items-start">
      {/* Line */}
      <img
        src={sLine}
        alt="Sline"
        className="w-[700px] absolute top-0 right-0 hide"
        objectFit="contain"
      />
      {/* Desc */}
      <div className="w-full lg:w-[50%] lg:mr-20 z-10">
        <h1 className="text-xl font-semibold text-black">
          Stay Updated with SPINGO
        </h1>
        <p className="text-xs mt-5">
          Subscribe now and receive a 10% discount <br /> on your next SPINGO
          subscription! <br />
          Stay updated and save on essential business tools{" "}
        </p>
        {/* Input Field*/}
        <div className="flex flex-row items-center py-6">
          <input
            placeholder="Enter Email"
            className="w-[80%] bg-white font-medium text-xs text-dark placeholder:text-dark py-3 px-5 rounded-md border border-primary"
          />
          <button className="bg-primary p-3 rounded-md ml-2">
            <FiSend color="white" size={20} />
          </button>
        </div>
        {/* Bottom Text */}
        <p className="text-xs lg:mb-0 mb-5">
          Subscribe now and receive a 10% discount <br /> on your next SPINGO
          subscription! <br />
          Stay updated and save on essential business tools{" "}
        </p>
      </div>
      {/* Image */}
      <div className="z-20 relative">
        <img
          src={subscribeImg}
          alt="Download"
          className="lg:w-[450px] w-[350px]"
        />
        {/* Absolute Card */}
        <div className="lg:w-[260px] w-[250px] bg-primary rounded-lg px-4 py-5 absolute lg:right-6 right-1 lg:-bottom-8 -bottom-14">
          <p className="text-xs text-white">
            See SPINGO in action with a personalized demo. Get guided insights,
            tailored solutions, and answers to your questions.
          </p>
          <div className="flex flex-row justify-between items-center mt-3">
            <p className="px-10 py-3 text-xs rounded-full bg-white text-primary">
              Demo
            </p>
            <button className="text-xs text-white flex flex-row items-center">
              Learn More <GrLinkNext color="white" className="ml-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
