import { FLine } from "../../constants/imports/features";

import React from "react";

const FeatureLine = () => {
  return (
    <div className="lg:py-4 py-10">
      <img src={FLine} alt="fmobile" className="w-full object-cover" />
    </div>
  );
};

export default FeatureLine;
