import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const GetStarted = () => {
  const data = [
    {
      title: "Inventory",
      headerOne: "Seamless inventory tracking",
      one: [
        "Scan barcodes or manually input items.",
        "Categorize inventory items for efficient organization.",
        "Centralize all inventory data for streamlined access.",
      ],
      headerTwo: "Real-time inventory insights and report",
      two: [
        "Monitor stock levels and inventory movements.",
        "Receive notifications for low stock or replenishment needs.",
        "Generate detailed inventory reports anytime, anywhere.",
      ],
    },
    {
      title: "Sales",
      headerOne: "Streamlined sales recording",
      one: [
        "Record sales transactions effortlessly",
        "Classify sales by product, category, or customer.",
      ],
      headerTwo: "Real-time sales insights and Sales reports",
      two: [
        "Monitor sales performance in real-time.",
        "Analyze sales trends and patterns.",
        "Generate comprehensive sales reports instantly.",
      ],
    },
    {
      title: "Expenses",
      headerOne: "Effortless expense capture",
      one: [
        "Easily upload receipts or expense documents.",
        "Categorize expenses for efficient organization.",
      ],
      headerTwo: "Real-time expense insights and Expense reports",
      two: [
        "Monitor sales performance in real-time.",
        "Analyze sales trends and patterns.",
        "Generate comprehensive sales reports instantly.",
      ],
    },
  ];

  return (
    <div className="bg-white container">
      <h1 className="lg:text-2xl text-xl font-bold text-black lg:mb-0 mb-2">
        Getting Started with Spingo
      </h1>
      <p className="text-xs font-light text-dark">
        Learn the basics, explore advanced features, and make the most out of
        your Spingo experience.
      </p>
      <div className="w-full">
        <div className="my-2 lg:py-5 py-0">
          {data.map((item, index) => (
            <Item key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GetStarted;

interface ItemProps {
  item: any;
}

const Item: React.FC<ItemProps> = ({ item }) => {
  const [onShowDetails, setOnShowDetails] = useState(false);

  return (
    <div className="w-full lg:px-12 px-4 bg-white rounded-lg shadow-xs border border-gray-200 my-5 p-5">
      <div
        className="flex justify-between items-center w-full cursor-pointer"
        onClick={() => setOnShowDetails(!onShowDetails)}
      >
        <h1 className="font-bold">{item.title}</h1>
        {/* Icon */}
        <div>
          {onShowDetails ? (
            <MdKeyboardArrowUp size={25} className="text-black" />
          ) : (
            <MdKeyboardArrowDown size={25} className="text-black" />
          )}
        </div>
      </div>
      {onShowDetails && (
        <div className="w-full flex lg:flex-row flex-col lg:justify-between items-start pt-4">
          <div className="lg:w-[45%] w-full lg:mb-0 mb-5">
            <h1 className="text-sm text-primary font-medium mb-3">
              {`1. ${item.headerOne}`}
            </h1>
            {item.one.map((text: string, index: number) => (
              <div key={index} className="flex flex-row items-center py-1">
                <GoDotFill size={12} className="text-black" />
                <p className="ml-2 text-xs font-medium text-black">{text}</p>
              </div>
            ))}
          </div>
          <div className="lg:w-[45%] w-full">
            <h1 className="text-sm text-primary font-medium mb-3">
              {`2. ${item.headerTwo}`}
            </h1>
            {item.two.map((text: string, index: number) => (
              <div key={index} className="flex flex-row items-center py-1">
                <GoDotFill size={12} className="text-black" />
                <p className="ml-2 text-xs font-medium text-black">{text}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
