import { appStore, googleStore } from "../../constants/import";
import { FLeftLine, fmobile } from "../../constants/imports/features";

import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FeatureDownload = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger animations once
    threshold: 0.5, // Trigger animation when 50% of the component is visible
  });

  return (
    <div
      ref={ref}
      className="w-full relative container grid lg:flex lg:flex-row-reverse flex-col justify-center bg-faint"
    >
      {/* Image */}
      <div>
        <img
          src={fmobile}
          alt="fmobile"
          className="w-[300px] lg:-mt-52 -mt-32 lg:mb-0 mb-10"
        />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
        transition={{ duration: 0.8 }}
        className="w-full lg:w-[48%] flex flex-col justify-center z-50"
      >
        <h1 className="text-black lg:text-3xl text-xl font-semibold z-50">
          Access SPINGO on-the-go
        </h1>
        <p className="text-black lg:text-sm text-xs mt-2 mb-8">
          Download our mobile app from the App Store for iOS or Google Play
          Store for Android. Manage your business efficiently, wherever you are.
        </p>
        {/* Download From Stores Button */}
        <div className="">
          {/* Google Store */}
          <img
            alt="Google Store"
            src={googleStore}
            className="lg:w-[160px] w-[200px] cursor-pointer z-50 mb-2"
          />
          <img
            alt="app Store"
            src={appStore}
            className="lg:w-[160px] w-[200px] cursor-pointer z-50"
          />
        </div>
      </motion.div>
      {/* Left Icon */}
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
        transition={{ duration: 0.8 }}
        className="relative"
      >
        <img
          src={FLeftLine}
          alt="DLeft"
          className="lg:w-[120px] w-[80px] absolute left-0 bottom-10"
        />
      </motion.div>
    </div>
  );
};

export default FeatureDownload;
