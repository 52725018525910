import {
  aboutOneImg,
  aboutTwoImg,
  aboutLeft,
  aboutRight,
} from "../../constants/imports/about";

import React from "react";
import { Button } from "../Generic";
import { rahma } from "../../constants/imports/team";
const CompanyOverview = () => {
  return (
    <div className="relative container flex lg:flex-row flex-col items-start justify-between mt-4">
      {/* LeftIcon */}
      <img
        src={aboutLeft}
        alt="DLEFT"
        className="w-[200px] absolute -left-2 bottom-56 hide"
      />
      {/* Images Container */}
      <div className="lg:w-[60%] w-[100%] flex items-start lg:gap-6 gap-0 z-50">
        <img
          src={aboutOneImg}
          alt="About One"
          className="lg:h-[550px]  h-[200px] w-[30%] object-contain"
        />
        <img
          src={aboutTwoImg}
          alt="About Two"
          className="lg:h-[550px] h-[200px] w-[68%] object-contain"
        />
      </div>
      {/* Company Overview */}
      <div className="lg:w-[35%] w-full mt-10 lg:mt-0 bg-white  z-50 lg:pb-52">
        <h1 className="big-heading">Company Overview</h1>
        <p className="text-sm text-dark lg:mt-6 mt-1 lg:mb-10 mb-2">
          Welcome to Spingo, where innovation meets efficiency in business
          management. Founded in 2024,{" "}
          <span className="text-primary font-medium">Spingo</span> is on a
          mission to revolutionize how businesses operate, streamlining
          processes and maximizing productivity.
        </p>
        <div className="flex lg:flex-col flex-row items-center gap-2">
          <Button
            width="lg:w-[200px] w-[120px]"
            text="Our Manifesto"
            onPress={() => {}}
          />
          {/* Product Manager */}
          <div className="flex items-center lg:my-8">
            <img
              src={rahma}
              className="lg:h-[60px] lg:w-[60px] h-[25px] w-[25px] rounded-full object-cover"
              alt="Rahma"
            />
            <div className="ml-2">
              <h1 className="lg:text-xl font-semibold text-sm">Rahma Sani</h1>
              <h1 className="lg:text-sm text-xs font-medium">
                Product Manager
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* LeftIcon */}
      <img
        src={aboutRight}
        alt="DRightaboutRight"
        className="w-[200px] absolute -right-2 bottom-40 -z-50 hide"
      />
    </div>
  );
};

export default CompanyOverview;
