import React from "react";
import { motion } from "framer-motion";

const AboutHeader = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="container"
    >
      <p className="text">About Us</p>
      <h1 className="lg:text-3xl text-lg font-semibold text- lg:w-[80%] w-full">
        Discover the story behind <span className="text-primary">Spingo</span>{" "}
        and how we&#39;re revolutionizing business management.
      </h1>
      <p className="text">
        See how Spingo is redefining business management and empowering
        organizations to thrive in the digital age.
      </p>
    </motion.div>
  );
};

export default AboutHeader;
