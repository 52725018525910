import { AboutUs } from "../../constants/import";
import React from "react";
import { GrLinkNext } from "react-icons/gr";

const AboutHome = () => {
  return (
    <div className="relative w-full">
      <img src={AboutUs} alt="About US" className="" />
      {/* Absolute Card */}
      <div className="w-full flex flex-row justify-center items-center">
        <div className="lg:w-[320px] w-[300px] bg-white shadow-md drop-shadow-md rounded-md lg:mt-0 -mt-14 py-3 px-5 lg:absolute lg:right-10 bottom-10">
          <h1 className="heading">OUR STORIES</h1>
          <p className="text">
            Explore our journey from idea to innovation, customer success
            stories, behind-the-scenes insights, and community impact at Spingo.
          </p>
          <p className="text">
            Dive into our fascinating journey, where we&#39;ve grown from a
            simple idea to a trusted leader in our industry
          </p>
          <div className="flex flex-row justify-end items-center mt-3">
            <button className="text-xs font-semibold text-primary flex flex-row items-center">
              Learn More <GrLinkNext className="ml-2 text-primary" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
