import { Link } from "react-router-dom";
import {
  contact,
  company,
  resources,
  subContact,
  socialData,
  contactAddress,
} from "../../constants/data";
import { logo } from "../../constants/import";

import React from "react";

const Footer = () => {
  return (
    <div className="container">
      <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3">
        {/* Col 1 Company */}
        <div className={"footer-col"}>
          <h1 className="heading">Company</h1>
          {company.map((item, index) => (
            <Link to={`/${item.link}`} key={index} className="text block">
              {item.title}
            </Link>
          ))}
        </div>
        {/* Col 3 Contact  */}
        <div className={"footer-col"}>
          <h1 className="heading">Support</h1>
          {contact.map((item, index) => (
            <p className="text" key={index}>
              {item}
            </p>
          ))}
        </div>
        {/* Col 3 Resources  */}
        <div className={"footer-col"}>
          <h1 className="heading">Resources</h1>
          {resources.map((item, index) => (
            <p className="text" key={index}>
              {item}
            </p>
          ))}
        </div>
        {/* Col 4 Addreesss, Logo, COntact Details */}
        <div className="footer-col">
          {/* About Spingo, SHow LG and Hide Mobile */}
          <div className="lg:block hidden">
            <img src={logo} alt="logo" className="w-[50px] h-[50px]" />
            <p className="text-black text-xs mt-2">
              SpinGo enables businesses to seamlessly manage inventory, sales,
              and accounting using advanced technology.
            </p>
            <div className={"mt-6"}>
              {subContact.map((item, index) => (
                <p className="text-xs font-medium text-black" key={index}>
                  {item}
                </p>
              ))}
            </div>
          </div>
          {/* Col 3 Contact Details Hide LG and Show Mobile  */}
          <div className={"lg:hidden block"}>
            <h1 className="heading">Contact</h1>
            {contactAddress.map((item, index) => (
              <p className="text" key={index}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full py-1 border-b border-b-dark">
        {/* Social Links */}
        <div className="flex flex-row items-center">
          {socialData.map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              key={index}
              className={`${index === 0 ? "" : ""} mr-3 p-2`}
              rel="noopener noreferrer"
            >
              <item.Icon size={18} className="text-primary" />
            </a>
          ))}
        </div>
      </div>
      <p className="text-black text-xs font-medium text-center mt-4">
        © 2024 SPINGO Nigeria Limited. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
