import { ComingSoon, Footer, NavBar } from "../components/Layouts";
import React from "react";

const Contact = () => {
  return (
    <div>
      <NavBar />
      <ComingSoon />
      <Footer />
    </div>
  );
};

export default Contact;
