import { AccessSpingo } from "../components/Home";
import {
  AboutHeader,
  AboutHome,
  CompanyOverview,
  DiscoverBluePrint,
  Team,
  Career,
} from "../components/About";
import React from "react";
import { Footer, NavBar } from "../components/Layouts";

const About = () => {
  return (
    <div>
      <NavBar />
      <AboutHeader />
      <AboutHome />
      <CompanyOverview />
      <DiscoverBluePrint />
      <Team />
      <Career />
      <AccessSpingo />
      <Footer />
    </div>
  );
};

export default About;
