import {
  abubakar,
  ibrahim,
  muhammad,
  rahma,
  semilore,
  seyanu,
} from "../../constants/imports/team";

import React from "react";

const Team = () => {
  const data = [
    { name: "Rahama Sani", role: "Product Manager", photo: rahma },
    { name: "Seyanu Ademuwa", role: "Product Designer", photo: seyanu },
    { name: "Semilore Balogun", role: "Growth Officer", photo: semilore },
    { name: "Abubakar Sadeeq", role: "Business Development", photo: abubakar },
    { name: "Ibrahim Dauda", role: "Software Engineer", photo: ibrahim },
    { name: "Muhammad Ibrahim", role: "Backend Engineer", photo: muhammad },
  ];
  return (
    <div className="container">
      {/* Header */}
      <div>
        <h1 className="text-2xl font-bold text-black">Our Team</h1>
        <h1 className="lg:text-2xl text-lg text-spingoGray font-bold">
          Leaders and Executives
        </h1>
        <p className="text-sm text-spingoGray mb-4 lg:mb-0">
          Meet the visionaries behind Spingo
        </p>
      </div>
      {/* Pictures */}
      <div className="flex flex-row justify-center gap-8 items-center flex-wrap">
        {data.map((item, index) => (
          <div key={index} className="lg:my-4 my-0">
            <img src={item.photo} className="w-[230px]" alt="Team" />
            <div className="mt-2">
              <h1 className="lg:text-lg text-md text-black lg:font-semibold font-medium lg:text-left text-center">
                {item.name}
              </h1>
              <p className="text-gray-500 font-extralight text-sm lg:text-left text-center">
                {item.role}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
