import { HeroBg, mobileHeroBg } from "../../constants/import";

import React from "react";
import { Button } from "../Generic";

const Hero = () => {
  return (
    <div className="relative flex flex-row lg:justify-start justify-center items-center mb-10 lg:pt-32 pt-14 lg:pb-20 pb-8 lg:px-20 px-6 lg:h-[100vh] h-[80vh]">
      <img
        src={HeroBg}
        alt="Hero Background "
        className="w-full absolute top-0 left-0 -z-30 h-full lg:block hidden"
      />
      <img
        src={mobileHeroBg}
        alt="Hero Background "
        className="w-full absolute top-0 left-0 -z-30 h-full lg:hidden block object-cover"
      />
      <div className="w-full lg:w-[40%]">
        <h1 className="lg:text-3xl text-xl lg:text-left text-center text-black lg:font-semibold font-medium px-2">
          Elevate Your Business with SPINGO - Your Ultimate Operations Ally
        </h1>
        <p className="mt-4 font-medium lg:text-left text-center text-sm text-black lg:px-3 px-0">
          SPINGO&#39;s platform integrates invoicing, inventory, and accounting
          seamlessly with AI expiry tracking for effortless operation.
        </p>
        <div className="w-full flex lg:flex-row flex-col justify-start lg:gap-8 items-center lg:pt-6 pt-14">
          <div className="lg:w-[200px] w-full">
            <Button text="Get Started" onPress={() => {}} />
          </div>
          <div className="lg:w-[200px] w-full">
            <Button text="Take a Tour" outlined onPress={() => {}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
