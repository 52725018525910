import {
  accessLeftLine,
  accessRightLine,
  appStore,
  googleStore,
} from "../../constants/import";

import React from "react";

const AccessSpingo = () => {
  return (
    <div className="w-full relative container flex flex-row justify-center items-center bg-primary">
      {/* Right Icon */}
      <img
        src={accessRightLine}
        alt="DRIGHT"
        className="lg:w-[120px] w-[60px] absolute right-1 lg:top-2 top-0"
      />
      <div className="w-full lg:w-[48%] flex flex-col justify-center items-center z-50">
        <h1 className="text-white lg:text-3xl text-xl font-semibold text-center z-50">
          Access SPINGO on-the-go
        </h1>
        <p className="text-white text-center lg:text-sm text-xs mt-2 mb-8">
          Download our mobile app from the App Store for iOS or Google Play
          Store for Android. Manage your business efficiently, wherever you are.
        </p>
        {/* Download From Stores Button */}
        <div className="flex lg:flex-row flex-col justify-center items-center gap-8">
          {/* Google Store */}
          <div>
            <img
              alt="Google Store"
              src={googleStore}
              className="lg:w-[160px] w-[200px] cursor-pointer z-50"
            />
          </div>
          <div>
            <img
              alt="App Store"
              src={appStore}
              className="lg:w-[160px] w-[200px] cursor-pointer z-50"
            />
          </div>
        </div>
      </div>
      {/* Left Icon */}
      <img
        src={accessLeftLine}
        alt="DLeft"
        className="lg:w-[120px] w-[80px] absolute left-0 bottom-10"
      />
    </div>
  );
};

export default AccessSpingo;
