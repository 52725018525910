import { Button } from "../../components/Generic";
import { comingSoon, mobileHeroBg } from "../../constants/import";
import React from "react";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
  const navigate = useNavigate();
  return (
    <div className="relative mb-10 lg:pt-32 pt-14 lg:pb-20 pb-8 lg:px-20 px-10 lg:h-[100vh] h-[70vh]">
      <img
        src={comingSoon}
        alt="Hero Background "
        className="w-full absolute top-0 left-0 -z-30 h-full lg:block hidden"
      />
      <img
        src={mobileHeroBg}
        alt="Hero Background "
        className="w-full absolute top-0 left-0 -z-30 h-full lg:hidden block object-cover"
      />
      <div className="w-full lg:w-[40%]">
        <h1 className="lg:text-3xl text-xl lg:text-left text-center  text-black font-semibold">
          Coming Soon!
        </h1>
        <p className="mt-4 lg:text-sm text-xs text-black">
          Page Under Construction...
        </p>
        {/* BUttons */}
        <div className="w-full flex lg:flex-row flex-col justify-between items-center pt-10 ">
          <div className="lg:w-[180px] w-full">
            <Button text="Back to Home" onPress={() => navigate("/")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
